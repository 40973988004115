import writeXlsxFile from "write-excel-file";

const createLeakSubTypeKey = (t) => {
  return {
    "Fire hydrant": t("leak-type.main.fire-hydrant"),
    Pipe: t("leak-type.main.pipe"),
    "Curb stop": t("leak-type.service.curb-stop-stop-tap"),
    "Utility loss": t("leak-type.customer-side.utility-loss"),
    "Connections-Fittings": t("leak-type.main.connection-fittings"),
    "Curb stop-Stop tap": t("leak-type.service.curb-stop-stop-tap"),
    Valve: t("leak-type.main.valve"),
    "Common supply": "Common supply",
    Meter: t("leak-type.service.meter"),
    "Parks & recreation": t("leak-type.customer-side.parks-and-recreation"),
  };
};

const createLeakTypeKey = (t) => {
  return {
    Main: t("leak-type.main"),
    Service: t("leak-type.total.service"),
    "Customer-Side": t("leak-type.total.customer-side"),
    WO: t("leak-type.total.wo"),
    Other: t("leak-type.other"),
  };
};

const remainingPoi = (data) => {
  const remainingPoiArrFilterd = data.filter((poi) => !poi.verification_result);
  const remainingPoiArr = remainingPoiArrFilterd.map((poi) => {
    return [
      { value: poi.utilis_id },
      { value: poi.release_date },
      { value: poi.address },
      { value: poi.inside_x },
      { value: poi.inside_y },
    ];
  });
  return remainingPoiArr;
};

const translateResults = (data, leakSubTypeKey, leakTypeKey, t) => {
  const translatedData = data.map((fieldResult) => {
    let leakSubType = "";
    if (fieldResult.verification_result === "Leak") {
      if (fieldResult.leak_type === "Main") {
        leakSubType = fieldResult.main_sub_type;
      } else if (fieldResult.leak_type === "Service") {
        leakSubType = fieldResult.service_sub_type;
      } else if (fieldResult.leak_type === "Customer-Side") {
        leakSubType = fieldResult.cust_sub_type;
      }
    }

    leakSubType = leakSubTypeKey[leakSubType];

    let checkVerificationResultForNull = fieldResult.verification_result;
    let checkVisibleForNull = fieldResult.visible;

    if (!checkVerificationResultForNull) {
      checkVerificationResultForNull = "(n/a)";
    }

    if (!checkVisibleForNull) {
      checkVisibleForNull = "(n/a)";
    }

    return [
      { value: fieldResult.utilis_finding },
      { value: t(checkVerificationResultForNull.toLowerCase()) },
      { value: fieldResult.repaired === 'yes' ? t("yes") : t("no") },
      { value: leakTypeKey[fieldResult.leak_type] },
      { value: leakSubType },
      { value: fieldResult.pipe_type },
      { value: fieldResult.timestamp_corrected },
      { value: t(checkVisibleForNull.toLowerCase()) },
      { value: fieldResult.actual_x },
      { value: fieldResult.actual_y },
      { value: fieldResult.__owner },
      { value: fieldResult.address },
      { value: fieldResult.comments },
      { value: fieldResult["reference "] },
    ];
  });
  return translatedData;
};

const crewPerformance = (dataFieldResult, dataMdcPilot) => {
  const crewPerformanceArr = [];

  dataFieldResult.map((fieldRes) => {
    const date = fieldRes.timestamp_corrected.split(" ")[0];
    const hour = fieldRes.timestamp_corrected.split(" ")[1].split(":")[0];
    const owner = fieldRes.__owner;
    const utilisId = fieldRes.utilis_finding.trim();
    const pipeLength = (() => {
      const result = dataMdcPilot.find((mdcPoi) => mdcPoi.utilis_id.trim() === utilisId)?.pipe_lengt;
      return Number.isNaN(result) ? 0 : result ?? 0;
    })();

    const isDateAndOwnerExist = crewPerformanceArr.find(
      (dateOwner) => dateOwner.date === date && dateOwner.owner === owner
    );

    if (!isDateAndOwnerExist) {
      crewPerformanceArr.push({
        date: date,
        owner: owner,
        horus: [hour],
        hoursSum: 2,
        utilisId: [utilisId],
        leaks: fieldRes.verification_result === "Leak" ? 1 : 0,
        suspected: fieldRes.verification_result === "Suspected" ? 1 : 0,
        quiet: fieldRes.verification_result === "Quiet" ? 1 : 0,
        unverifiable: fieldRes.verification_result === "Unverifiable" ? 1 : 0,
        pipeLength: pipeLength,
      });
    } else {
      // check verification results
      if (fieldRes.verification_result === "Leak") {
        isDateAndOwnerExist.leaks++;
      } else if (fieldRes.verification_result === "Suspected") {
        isDateAndOwnerExist.suspected++;
      } else if (fieldRes.verification_result === "Quiet") {
        isDateAndOwnerExist.quiet++;
      } else if (fieldRes.verification_result === "Unverifiable") {
        isDateAndOwnerExist.unverifiable++;
      }

      if (!isDateAndOwnerExist.horus.includes(hour)) {
        isDateAndOwnerExist.horus.push(hour);
        isDateAndOwnerExist.hoursSum += 1;
      }
      if (!isDateAndOwnerExist.utilisId.includes(utilisId)) {
        isDateAndOwnerExist.utilisId.push(utilisId);
        isDateAndOwnerExist.pipeLength += pipeLength;
      }
    }
  });

  return crewPerformanceArr;
};

const createHeader = (data) => {
  return data.map((header) => {
    return {
      value: header,
      fontWeight: "bold",
    };
  });
};

export const exportToExcelFile = async (projectName, data, t) => {
  const leakSubTypeKey = createLeakSubTypeKey(t);
  const leakTypeKey = createLeakTypeKey(t);
  const HeaderCollectedData = createHeader([
    t("field-results.id"),
    t("field-results.investigation-results"),
    t("repaired"),
    t("field-results.leak-type"),
    t("field-results.leak-sub-type"),
    "Pipe Type",
    t("field-results.date"),
    t("field-results.visible"),
    "Actual X",
    "Actual Y",
    "Crew",
    t("field-results.address"),
    t("field-results.comments"),
  ]);
  const HeaderRemainingPois = createHeader([
    "ID",
    "Realse Date",
    "Address",
    "X",
    "Y",
  ]);
  const HeaderCrewPerformance = createHeader([
    "Date",
    "Crew ID",
    "Estimated Work Hours In Field",
    "Number of POI Investigated",
    "Number Of Leak",
    "Number Of Suspected",
    "Number Of Quiet",
    "Number Of Unverifiable",
    "Investigated Pipe Length (km)",
  ]);

  const crewPerformanceArr = crewPerformance(
    [...data.mdcFeildResTest.field_results],
    [...data.mdcPilot.data]
  ).map((crewPerformance) => {
    return [
      { value: crewPerformance.date },
      { value: crewPerformance.owner },
      { value: crewPerformance.hoursSum },
      { value: crewPerformance.utilisId.length },
      { value: crewPerformance.leaks },
      { value: crewPerformance.suspected },
      { value: crewPerformance.quiet },
      { value: crewPerformance.unverifiable },
      { value: crewPerformance.pipeLength },
    ];
  });

  const translateResultsArr = translateResults(
    [...data.mdcFeildResTest.field_results],
    leakSubTypeKey,
    leakTypeKey,
    t
  );

  const remainingPoiArr = remainingPoi([...data.mdcPilot.data]);
  const sheetCollectedData = [HeaderCollectedData, ...translateResultsArr];
  const sheetCrewPerformance = [HeaderCrewPerformance, ...crewPerformanceArr];
  const sheetRemainingPois = [HeaderRemainingPois, ...remainingPoiArr];

  const currentDate = new Date().toJSON().slice(0, 10);
  const fileName = `${projectName}_${currentDate}.xlsx`;
  await writeXlsxFile(
    [sheetCollectedData, sheetCrewPerformance, sheetRemainingPois],
    {
      fileName: fileName,
      sheets: ["Collected Data", "Crew Performance", "Remaining POIs"],
    }
  );
};
